var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['game-container', _vm.cookie.nightMode && 'nightMode']},[_c('Transition',[(this.msg.showMessage)?_c('div',{staticClass:"message"},[_vm._l((this.msg.message),function(line,index){return _c('span',{key:("msg-row " + index)},[_vm._v("\n          "+_vm._s(line)),_c('br')])}),_vm._v(" "),(this.msg.showEndBlurb)?_c('span',[_c('br'),_vm._v(" "),_c('button',{on:{"click":function($event){return _vm.copyResultGrid()}}},[_vm._v("Share")]),_vm._v("  \n          "),_c('button',{on:{"click":function($event){return _vm.goToInfinite()}}},[(this.daily)?_c('span',[_vm._v("Practice")]):_c('span',[_vm._v("Play Again")])])]):_vm._e()],2):_vm._e()]),_vm._v(" "),_c('header',[_c('h1',{staticClass:"game-header"},[_c('GameHeader',{attrs:{"daily":_vm.daily,"cookie":this.cookie},on:{"change":this.cookieChange}},[_vm._v("\n            RIT BAJA-DLE\n          ")])],1)]),_vm._v(" "),_c('div',{class:[("rows-" + _vm.numGuesses)],attrs:{"id":"board"}},_vm._l((_vm.board),function(row,index){return _c('div',{key:("row " + index),class:[
                'row',
                _vm.shakeRowIndex === index && 'shake',
                _vm.success && _vm.currentRowIndex === index && 'jump',
                ("t" + (_vm.word.length))
            ]},_vm._l((row),function(tile,subindex){return _c('div',{key:("row " + index + " tile " + subindex),class:[
                  'tile',
                  _vm.cookie.nightMode && 'nightMode',
                  tile.letter.length > 0 && 'filled',
                  tile.state.name !== 'initial' && 'revealed'
                ]},[_c('div',{class:[
                    'front',
                    _vm.cookie.nightMode && 'nightMode',
                    ("d" + subindex),
                    _vm.success && _vm.currentRowIndex == index && 'hideTile'
                  ]},[_vm._v("\n                    "+_vm._s(tile.letter)+"\n                ")]),_vm._v(" "),_c('div',{class:['back', tile.state.name, ("d" + subindex)]},[_vm._v("\n                    "+_vm._s(tile.letter)+"\n                ")])])}),0)}),0),_vm._v(" "),_c('Keyboard',{attrs:{"letterStates":_vm.letterStates},on:{"key":_vm.onKey}}),_vm._v(" "),_c('textarea',{attrs:{"id":"copy-text"}},[_vm._v("a")])],1)}
var staticRenderFns = []
export { render, staticRenderFns }